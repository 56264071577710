<template>
    <perfect-scrollbar :class="{'mini':mini,'more':showMore}">

        <div class="gender" v-if="!onlySearch">

            <div :class="{active:params.gender === 2}" @click="genderChanged(2)">女士</div>
            <div :class="{active:params.gender === 1}" @click="genderChanged(1)">男士</div>

        </div>

        <template v-if="showMore">

            <div class="search">
                <input class="search-input" v-model="keywords" type="text" placeholder="搜索您感兴趣的单品"/>
                <button @click="search" class="btn">搜索</button>
            </div>

            <div class="categories-groups">
                <div class="categories">

                    <template v-for="(cate,k) in categories">
                        <button :key="k" class="btn" :class="{'btn-white' : params.cate !== cate.id }"
                                @click="cateClicked(cate,cate.id)">{{ cate.name }}
                        </button>
                    </template>

                </div>

                <div class="categories-sub">

                    <template v-for="(cate) in categories">

                        <span :key="cate.id">

                            <template v-if="cate.id === category.lv1 ">

                                <template v-for="(child) in cate.children">

                                    <button :key="child.id" class="btn"
                                            :class="{'btn-white' : params.cate !== child.id }"

                                            @click="cateClicked(child,0,child.id)">{{ child.name }}

                                    </button>

                                </template>

                            </template>
                        </span>
                    </template>
                </div>

                <div class="categories-sub">

                    <template v-for="(cate) in categories">

                        <span :key="cate.id">

                            <template v-if="cate.id === category.lv1 ">

                                <template v-for="(child) in cate.children">

                                    <span :key="child.id">

                                        <template v-if="child.id === category.lv2 ">

                                            <template v-for="(subChild) in child.children">

                                                <button :key="subChild.id" class="btn"
                                                        :class="{'btn-white' : params.cate !== subChild.id }"

                                                        @click="cateClicked(subChild)">{{ subChild.name }}

                                                </button>

                                            </template>
                                        </template>
                                    </span>

                                </template>

                            </template>
                        </span>
                    </template>
                </div>
            </div>
        </template>

        <template v-if=" mini ">
            <div class="list-filter">

                <template v-if="showMore">

                    <div class="colors">

                        <span class="filter-title">色系：</span>
                        <template v-for="(color,k) in colors">

                            <button @click="colorClicked(color)" :key="k" :class="`color-${color}`">
                                <span v-if=" params.color === color " class="selected"></span>
                            </button>

                        </template>

                    </div>

                    <div class="plates">
                        <span class="filter-title">来源：</span>
                        <check-box name="plate" value="tb" :checked="params.plate === 'tb' " @change="checkBoxSelected">
                            <span class="iconfont pc-taobao"></span>淘宝
                        </check-box>
                        <check-box name="plate" value="jd" :checked="params.plate === 'jd' " @change="checkBoxSelected">
                            <span class="iconfont pc-jingdong"></span>京东
                        </check-box>
                        <check-box name="plate" value="vip" :checked="params.plate === 'vip' "
                                   @change="checkBoxSelected">
                            <span class="iconfont pc-weipinhui"></span>唯品会
                        </check-box>

                    </div>

                    <div class="prices">
                        <span class="filter-title">价格：</span>
                        <template v-for="(label,value) in prices">

                            <check-box :key="value" :checked="params.price === value" @change="checkBoxSelected"
                                       name="price" :value="value">
                                {{ label }}
                            </check-box>

                        </template>

                    </div>
                </template>
                <div class="sorts">
                    <template v-for="(label,slug) in sorts">
                        <button :key="slug"
                                :class="{'selected' : params.order_by === slug }"
                                @click="sortClicked(slug)">{{ label }}
                            <template v-if=" slug === 'price' ">

                                <template v-if="params.order_by === 'price' ">
                                    <span v-if="params.order_type === 'asc' " class="iconfont pc-shang"></span>
                                    <span v-else class="iconfont pc-xia"></span>
                                </template>
                                <span v-else class="iconfont pc-weixuan"></span>
                            </template>
                        </button>
                    </template>
                </div>

            </div>

            <button class="exchange-btn" @click="showMore = !showMore">
                <template v-if="showMore">
                    收起<span class="iconfont pc-shangjiantou"></span>
                </template>
                <template v-else>
                    展开<span class="iconfont pc-xiajiantou"></span>
                </template>
            </button>
        </template>
        <template v-else>
            <div class="list-filter">

                <div class="plates">
                    <span class="filter-title">来源：</span>
                    <check-box name="plate" value="tb" :checked="params.plate === 'tb' " @change="checkBoxSelected">
                        <span class="iconfont pc-taobao"></span>淘宝
                    </check-box>
                    <check-box name="plate" value="jd" :checked="params.plate === 'jd' " @change="checkBoxSelected">
                        <span class="iconfont pc-jingdong"></span>京东
                    </check-box>
                    <check-box name="plate" value="vip" :checked="params.plate === 'vip' "
                               @change="checkBoxSelected">
                        <span class="iconfont pc-weipinhui"></span>唯品会
                    </check-box>

                </div>

                <div class="colors">

                    <span class="filter-title">色系：</span>
                    <template v-for="(color,k) in colors">

                        <button @click="colorClicked(color)" :key="k" :class="`color-${color}`">
                            <span v-if=" params.color === color " class="selected"></span>
                        </button>

                    </template>

                </div>

                <div class="prices">
                    <span class="filter-title">价格：</span>
                    <div class="prices-inputs">
                        <input v-model="priceInputs.start" placeholder="最低价" type="text"/>
                        <span class="de">-</span>
                        <input v-model="priceInputs.end" placeholder="最高价" type="text"/>
                        <button @click="triggerChange"></button>
                    </div>

                </div>

            </div>
            <div class="sorts-filter">

                <div class="sorts">
                    <template v-for="(label,slug) in sorts">
                        <button :key="slug"
                                :class="{'selected' : params.order_by === slug }"
                                @click="sortClicked(slug)">{{ label }}
                            <template v-if=" slug === 'price' ">

                                <template v-if="params.order_by === 'price' ">
                                    <span v-if="params.order_type === 'asc' " class="iconfont pc-shang"></span>
                                    <span v-else class="iconfont pc-xia"></span>
                                </template>
                                <span v-else class="iconfont pc-weixuan"></span>
                            </template>
                        </button>
                    </template>
                </div>
            </div>
        </template>

    </perfect-scrollbar>
</template>

<script>
import CheckBox from "./CheckBox";
import api from "../repo/api";

export default {
    name: "GoodsFilter",
    components: {CheckBox},
    model: {
        event: 'change',
        prop: 'filter'
    },
    props: {
        filter: Object,
        mini: Boolean,
        onlySearch: Boolean,
    },
    data() {
        return {
            params: {
                keywords: '',
                gender: 2,
                cate: 0,
                order_by: '',
                order_type: '',
                plate: '',
                price: '',
            },
            category: {
                'lv1': 0,
                'lv2': 0,
            },
            priceInputs: {
                start: '',
                end: '',
            },
            colors: [
                'orange',
                'gray',
                'yellow',
                'red',
                'black',
                'cyan',
                'blue',
                'magenta',
                'purple',
                'green',
                'white'
            ],
            showMore: !this.mini,
            // showMore: true,
            keywords: '',
            categories: [],
            sorts: {
                'normal': '综合',
                'hot': '热度',
                'new': '新品',
                'price': '价格',
            },
            prices: {
                '0-99': '0~99',
                '100-199': '100~199',
                '200-399': '200~399',
                '400-0': '400以上',
            },
            originCategories: [],
        }
    },
    mounted() {
        this.getCategories()
    },
    methods: {
        search() {
            this.triggerChange();
        },
        colorClicked(color) {

            if (this.params.color === color) {
                color = ''
            }

            this.params.color = color
            this.triggerChange();

            this.$forceUpdate()
        },
        genderChanged(gender) {
            this.params.gender = gender

            this.pickGenderCategory()

            this.triggerChange();
        },
        cateClicked(cate, lv1, lv2) {

            this.params.cate = (this.params.cate === cate.id) ? '' : cate.id

            if (lv1) this.category.lv1 = this.category.lv1 === lv1 ? 0 : lv1
            if (lv2) this.category.lv2 = this.category.lv1 === lv2 ? 0 : lv2

            this.triggerChange();
        },
        checkBoxSelected(name, value, selected) {
            this.params[name] = selected ? value : '';

            this.triggerChange();
        },
        getCategories() {

            if (localStorage['goods_categories']) {
                this.originCategories = JSON.parse(localStorage['goods_categories'])
                this.pickGenderCategory()
            }


            api.getCategories((data) => {

                localStorage['goods_categories'] = JSON.stringify(data.data)

                this.originCategories = data.data
                this.pickGenderCategory()

            })
        },
        pickGenderCategory() {
            let arr = []

            this.params.cate = ''

            this.originCategories.forEach(item => {
                if (item.gender === this.params.gender) arr.push(item)
            })

            this.categories = arr
        },
        sortClicked(slug) {

            let orderType = 'desc';

            if (slug === 'price' && this.params.order_by === slug) {
                orderType = this.params.order_type === 'desc' ? 'asc' : 'desc';
            }

            this.params.order_type = orderType;
            this.params.order_by = slug;

            this.triggerChange();
        },
        triggerChange() {
            if (!this.mini) {
                this.params.price = `${this.priceInputs.start}-${this.priceInputs.end}`
            }

            this.params.keywords = this.keywords
            this.$emit('change', this.params);
        }
    }
}
</script>

<style lang="less" scoped>
.gender {
    font-size: 14px;
    line-height: 22px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    border-bottom: 1px solid #EEEEEE;


    div {
        margin-right: 64px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        position: relative;
        cursor: pointer;
    }

    .active {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);

        &:after {
            content: ' ';
            height: 2px;
            width: 100%;
            background: rgba(0, 0, 0, 0.8);
            position: absolute;
            left: 0;
            bottom: -10px;
        }
    }
}

.search {
    max-width: 670px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    input, button {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    input {
        flex: 1;
        padding: 8px 16px;
        outline: none;
        border: 1px solid rgba(51, 51, 51, 0.8);
    }

    button {
        width: 70px;
        padding: 8px;
        border: none;
    }
}

.categories-sub {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
    background-color: white;

    button {
        margin-right: 15px;
        margin-bottom: 15px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.categories-groups {
    margin-bottom: 41px;
    margin-top: 56px;
}

.categories {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;

    button {
        margin-right: 15px;
        margin-bottom: 15px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.list-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sorts {
    button {

        background-color: transparent;
        border: none;
        margin-right: 48px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        cursor: pointer;

        &.selected {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.8);
        }
    }

    .iconfont {
        font-size: 14px;
    }
}

.plates .iconfont {
    margin-right: 5px;
}

.mini {
    position: relative;

    .categories-groups {
        margin-top: 32px;
        margin-bottom: 7px;

        .categories {
            justify-content: left;
        }
    }

    .categories-sub {
        justify-content: left;
    }

    .list-filter {
        flex-direction: column;

        & > div {
            margin-bottom: 22px;
        }
    }


}

.exchange-btn {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    position: absolute;

    right: 0;
    bottom: 22px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .iconfont {
        font-size: 12px;
        margin-left: 5px;
    }
}

.mini {
    height: 114px;

    &.more {
        height: 335px;
    }
}

.search-input::placeholder {
    color: #CCC;
}

.colors {
    button {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        outline: none;
        border: none;
        border-radius: 16px;
        cursor: pointer;
        background-image: url("/assets/images/colors.png");
        background-size: auto 16px;
    }

    .color-black {
        background-position: 0 0;
    }

    .color-white {
        background-position: -24px 0;

        .selected {
            background-image: url("/assets/images/color-select-black.png");
        }
    }

    .color-gray {
        background-position: -(24  * 2px) 0;
    }

    .color-red {
        background-position: -(24  * 3px) 0;
    }

    .color-orange {
        background-position: -(24  * 4px) 0;
    }

    .color-yellow {
        background-position: -(24  * 5px) 0;
    }

    .color-green {
        background-position: -(24  * 6px) 0;
    }

    .color-blue {
        background-position: -(24  * 7px) 0;
    }

    .color-purple {
        background-position: -(24  * 8px) 0;
    }

    .color-magenta {
        background-position: -(24  * 9px) 0;
    }

    .color-cyan {
        background-position: -(24  * 10px) 0;
    }


    .selected {
        background-image: url("/assets/images/color-select-white.png");
        display: inline-block;
        width: 8px;
        height: 8px;
        background-size: contain;
    }
}

.filter-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
}

.colors, .prices, .plates {
    display: flex;
    align-items: center;
}

.sorts-filter {
    margin-top: 32px;
    display: flex;
    justify-content: right;

    .sorts button:last-child {
        margin-right: 0;
    }
}

.prices-inputs {
    display: flex;
    align-items: center;

    input {
        height: 32px;
        border-radius: 1px;
        border: 1px solid #EEEEEE;
        width: 72px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
    }

    .de {
        color: #979797;
        display: inline-block;
        margin-right: 8px;
        margin-left: 8px;
    }

    button {
        width: 32px;
        height: 32px;
        border-radius: 1px;
        border: 1px solid #EEEEEE;
        background-image: url("/assets/images/prices-btn.png");
        background-size: 50% 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: white;
        margin-left: 10px;
        cursor: pointer;
    }
}


</style>