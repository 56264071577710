<template>
    <label class="checkbox">
        <span>
            <input type="checkbox" :value="value" @change="changed" :checked="isChecked">
            <slot/>
        </span>
    </label>
</template>

<script>
    export default {
        name: "CheckBox",
        props: {
            name: String,
            value: {},
            checked: Boolean,
        },
        watch: {
            checked() {
                this.isChecked = this.checked
            }
        },
        data() {
            return {
                isChecked: this.checked
            }
        },
        methods: {
            changed() {

                this.isChecked = !this.isChecked
                this.$emit('change', this.name, this.value, this.isChecked)

            }
        }
    }
</script>

<style lang="less" scoped>
    label {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        user-select: none;
    }


    .checkbox {
        display: inline-block;
        margin-right: 32px;

        cursor: pointer;

        span {
            display: flex;
            align-items: center;
        }

        input {
            margin-right: 8px;
            width: 16px;
            height: 16px;
        }
    }


</style>